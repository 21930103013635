
const convertCities = (cities) => {
    let subscribedCityList = []
    for (let city of cities) {
        if (city === 'Kitchener') {
            subscribedCityList = [...subscribedCityList, 'Kitchener']
        }
        if (city === 'Waterloo') {
            subscribedCityList = [...subscribedCityList, 'Waterloo']
        }
        if (city === 'Guelph') {
            subscribedCityList = [...subscribedCityList, 'Guelph']
        }
        if (city === 'Cambridge - Hespeler - Preston') {
            subscribedCityList = [...subscribedCityList, 'Cambridge']
        }
        if (city === 'Hamilton Region - Stoney Creek') {
            subscribedCityList = [...subscribedCityList, 'Hamilton', 'Burlington']
        }
        if (city === 'Brantford') {
            subscribedCityList = [...subscribedCityList, 'Brantford']
        }
        if (city === 'Brant County') {
            subscribedCityList = [...subscribedCityList, 'Brant', 'Norfolk']
        }
        if (city === 'Woolwich - Wellesley - Wilmot - N Dumfries - Flamborough') {
            subscribedCityList = [...subscribedCityList, 'Woolwhich', 'Norfolk', "Wilmot", 'North Dumfries', 'Flamborough', 'Hamilton', "Blandford-Blenheim", "Wellesley"]
        }
        if (city === 'Puslinch  - Halton Hills') {
            subscribedCityList = [...subscribedCityList, 'Puslinch', "Milton", "Halton Hills", 'West Lincoln', "Caledon", 'Scugog', "Moffat"]
        }
        if (city === 'Guelph/Eramosa - Center Wellington - Wellington North - Erin - Minto') {
            subscribedCityList = [...subscribedCityList, 'Guelph/Eramosa', "Centre Wellington", "Wellington North", "Minto", "East Garafraxa", "Erin", "Mapleton", "Amaranth"]
        }
        if (city === 'Woodstock') {
            subscribedCityList = [...subscribedCityList, 'Woodstock']
        }
        if (city === 'Oxford County') {
            subscribedCityList = [...subscribedCityList, 'Ingersoll', "Norfolk", "Ingersoll", "East Zorra-Tavistock"]
        }
    }
    return subscribedCityList
}

export default convertCities;